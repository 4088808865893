$mainColor1: #8ABF55;
$mainColor2: #802059;
$mainColor3: #F8CD46;
// $herbalifeGreen: #8ABF55;
// $mainColor: #8ABF55;
// $herbalifePurple: #802059;
$lightgray: #E2E2E2;
$gray: #939393;
$darkgray: #232323;
$white: #fff;

$pink: #c80a48;
$blue: #58A4F8;
// $lightgray: #D4D1CD;
$orange: #EE813C;
$turquoise: #30D5C8;

// @mixin media-phonever {
//   @media screen and (max-width:450px) and (orientation: portrait) {
//     @content
//   }
// }

// @mixin media-phonehor {
//   @media screen and (max-width:900px) and (orientation: landscape) {
//     @content
//   }
// }

// @mixin media-w1000 {
//   @media screen and (max-width:1000px) and (orientation: portrait) {
//     @content
//   }
// }