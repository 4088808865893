@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  list-style-type: none;
  list-style: none;
  overscroll-behavior: none;
}

html {
  scroll-behavior: smooth;
}

#root {
  background-color: #E2E2E2;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
body.fixed {
  overflow: hidden;
}
body #root {
  display: flex;
  flex-direction: column;
  align-items: center;
}/*# sourceMappingURL=main.css.map */