@import 'config';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


// Preventing default context menu on longpress / longclick in mobile Safari (iPad / iPhone) if preventDefault() added in JS
// https://stackoverflow.com/questions/12304012/preventing-default-context-menu-on-longpress-longclick-in-mobile-safari-ipad

// body {
//   -webkit-touch-callout: none !important;
// }

// a {
//   -webkit-user-select: none !important;
// }

// ============== \\

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  list-style-type: none;
  list-style: none;
  overscroll-behavior: none; // Disabling Pull-to-Refresh Feature on Mobile Browsers
  // transition: .2s linear;
}

html {
  // font-size: 62.5%;
  // overflow-x: hidden;
  // scroll-padding-top: 9rem;
  scroll-behavior: smooth;
}

// ::-webkit-scrollbar {
//   width: 0;
// }

// html::-webkit-scrollbar {
//   width: .8vw;
// }

// html::-webkit-scrollbar-track {
//   background: transparent !important;
// }

// html::-webkit-scrollbar-thumb {
//   background: #fff;
//   border-radius: 5vw;
// }

#root {
  background-color: $lightgray;
  width: 100vw;
  // height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  &.fixed {
    overflow: hidden;
  }

  & #root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}